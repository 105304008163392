<template>
    <!-- <div :class="[flag? '': 'content-box']"> -->
        <!-- <div :class="[flag? '': 'container']"> -->
            <div class="content">
            <h4 class="global_head_title">服务人员添加/编辑</h4>
            <div class="content_row flex">
                <label class="info" for="">姓名 <span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="data.name" size="large" placeholder="请输入姓名"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">性别<span class="redS">*</span></label>
                  <div class="content_right">
                     <a-select v-model="data.sex" class=" ant-select-lg">
                        <a-select-option :value="1">男</a-select-option>
                        <a-select-option :value="2">女</a-select-option>
                    </a-select>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">出生日期</label>
                  <div class="content_right">
                    <!-- <a-input v-model="data.age" size="large" type="number" placeholder="请输入年龄"/> -->
                     <a-date-picker v-model="age" :inputReadOnly="true" size="large" @change="onPickTime" />
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">身份证号<span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="data.code" size="large" placeholder=" 18位，例如142524199005201122"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">身份证正面<span class="redS">*</span></label>
                  <div class="content_right">
                       <template>
                            <img style="width:200px;display: block;" v-if="data.idcardfront" :src="ajaxUrl+'/scrm/index/getFile?id='+data.idcardfront">
                            <label class="fileLabel" for="image">
                            <a-icon type="upload" /> 身份证正面
                            </label>
                            <input type="file" id="image" ref="image" style="display:none" @change="imgUpload($event)">
                        </template>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">身份证背面<span class="redS">*</span></label>
                  <div class="content_right">
                       <template>
                            <img style="width:200px;display: block;" v-if="data.idcardback" :src="ajaxUrl+'/scrm/index/getFile?id='+data.idcardback">
                            <label class="fileLabel" for="image1">
                            <a-icon type="upload" /> 身份证背面
                            </label>
                            <input type="file" id="image1" ref="image1" style="display:none" @change="imgUpload1($event)">
                        </template>
                </div>
            </div>
            
             <div class="content_row flex">
                <label class="info" for="">电话<span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="data.tel" size="large" placeholder="请输入电话"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">工资基数<span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input prefix="￥" v-model="data.base" size="large" placeholder="数字，例如 500"/>
                </div>
            </div>
           
            <!-- <div class="content_row flex">
                <label class="info" for="">职务</label>
                  <div class="content_right">
                    <a-select v-model="data.post" class="ant-select-lg">
                        <a-select-option :value="'正式工'">正式工</a-select-option>
                        <a-select-option :value="'临时工'">临时工</a-select-option>
                        <a-select-option :value="'负责人'">负责人</a-select-option>
                    </a-select>
                </div>
            </div> -->
            <div class="content_row flex">
                <label class="info" for="">银行卡号</label>
                  <div class="content_right">
                    <a-input v-model="data.card" size="large" placeholder="请输入银行卡号"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">所属部门</label>
                  <div class="content_right">
                     <a-select show-search option-filter-prop="children" v-model="data.department" class=" ant-select-lg">
                        <a-select-option :value="item.did" v-for="(item,index) in teamList" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">紧急联系人</label>
                  <div class="content_right">
                    <a-input v-model="data.contacts" size="large" placeholder="请输入紧急联系人"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">紧急联系人电话</label>
                  <div class="content_right">
                    <a-input v-model="data.contactstel" size="large" placeholder="请输入紧急联系人电话"/>
                </div>
            </div>
              <div class="content_row flex">
                <label class="info" for="">状态</label>
                  <div class="content_right">
                   <a-select v-model="data.status" class="ant-select-lg">
                        <a-select-option :value="1">在职</a-select-option>
                        <a-select-option :value="2">离职</a-select-option>
                    </a-select>
                </div>
            </div>
             <!-- <div class="content_row flex">
                <label class="info" for="">跟进人</label>
                  <div class="content_right">
                     <a-select v-model="data.uid"  class="ant-select-lg">
                        <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                </div>
            </div> -->
            <div class="content_row flex">
                <label class="info" for="">工作点<span class="redS">*</span></label>
                  <div class="content_right">
                     <a-select show-search option-filter-prop="children" v-model="data.opid" class="ant-select-lg">
                        <a-select-option :value="item.id" v-for="(item,index) in operatList" :key="index">{{item.oname}}</a-select-option>
                    </a-select>
                    <a-icon type="close-circle" style="font-size:20px;margin-left:10px;margin-top:5px;color:#666" @click="data.opid=''" />
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">是否有保洁证</label>
                  <div class="content_right">
                    <a-select v-model="data.cleaning" class="ant-select-lg">
                        <a-select-option :value="2">有</a-select-option>
                        <a-select-option :value="1">没有</a-select-option>
                    </a-select>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">是否有绿化证</label>
                  <div class="content_right">
                    <a-select v-model="data.green" class="ant-select-lg">
                        <a-select-option :value="2">有</a-select-option>
                        <a-select-option :value="1">没有</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">入职时间<span class="redS">*</span></label>
                  <div class="content_right">
                       <a-date-picker v-model="createtime" :inputReadOnly="true" size="large" @change="onPickCTime" />
                </div>
            </div>
            
            <div class="content_row flex" >
                <label class="info" for="">备注</label>
                <div class="content_right">
                    <a-textarea v-model="data.remarks" placeholder="请输入任务描述" :auto-size="{ minRows: 5, maxRows: 8 }"/>
                </div>
            </div>
            
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
            </div>
        <!-- </div> -->
        <!-- </div> -->
</template>
<script>
// import $ from "jquery";
import {isMobile,ajaxUrl,requestXml, getDateTime,fileUpload} from '../../../assets/js/request';
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            staff:'', //员工列表
            skillid:'', //技能列表
            data:{
                "name": "",
                "number": "",
                "sex": "",
                "age": "",
                "code": "",
                "tel": "",
                'department':'',
                "status": "",
                "post": "",
                "card": "",
                "contacts": "",
                "contactstel": "",
                "uid": "",
                "remarks": "",
                "base": "",
                "cleaning":'',
                "green":'',
                "createtime":'',
                'idcardfront':'',
                'idcardback':'',
                'opid':''
            },
            createtime:'', //保险到期时间
            age:'', //出身日期
            selSkillid:'',  //技能选项
            operatList:'', //工作点列表
            teamList:'', //部门列表
            imageId:'',
            imageId1:''
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        console.log(this.id);
        // this.id=4;
        // this.getStaff();
        // this.getSkillid();
        if(this.id){
            this.getDetail();
        }
        this.getOperat();
        this.getTeamList();
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
        submit(){
            let url='/jieb/Worker/workeradd';
            if(this.id){
                url='/jieb/Worker/workeredit';
                this.data.id=this.id;
            }
            delete this.data.responsibility_name;
            delete this.data.departmentname;
            requestXml(url,"POST",(res) => {
                console.log(res);
                if(res!='error'){
                    if(!this.id){
                        this.$message.success('成功');
                    }
                    let path="/scrm_pc/worker/workersMy";
                    if(isMobile()){
                        path = '/scrm_wap/worker/workersMy';
                    }
                    this.$router.push({
                        path: path,
                    });
                }
            },this.data)
        },
          // 获取部门
        getTeamList() {
            requestXml("/jieb/Target/department", "POST", (res) => {
                this.teamList = res;
            });
        },
        onPickTime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.data.age= Math.round(time/1000); 
        },
         // 选择开始时间
        onPickCTime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.data.createtime= Math.round(time/1000); 
        },
        // 获取工作点
        getOperat(){
             requestXml("/jieb/Operating/operatshow","POST",(res) => {
                this.operatList = res.list;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        // 获取技能分类
        getSkillid(){
             requestXml("/jieb/Temporary/skillshow","POST",(res) => {
                this.skillid=res.list;
                // console.log(this.skillid);
                // console.log(JSON.stringify( this.skillid));
                if(this.id){
                    this.getDetail();
                }
                // console.log(this.skillid);
            },{"page":{"curpage":1,"pagesize":999999}})
        },
         getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staff=res;
            })
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Worker/workeredit","GET",(res) => {
                this.createtime=getDateTime(res.createtime);
                this.age=getDateTime(res.age);
                this.data = Object.assign({}, res);
                 this.data.opid==0?this.data.opid='':'';
                 this.data.cleaning==0?this.data.cleaning='':'';
                 this.data.green==0?this.data.green='':'';
                 this.data.status==0?this.data.status='':'';
                 this.data.department==0?this.data.department='':'';
            },{id: this.id})
        },
        // 图片上传
        imgUpload(e) {
            fileUpload(e, (res) => {
                this.data.idcardfront = res.id;
                this.$refs.image.value = "";
            });
        },
        // 图片上传
        imgUpload1(e) {
            fileUpload(e, (res) => {
                this.data.idcardback = res.id;
                this.$refs.image1.value = "";
            });
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
    
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 100px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        
    }
</style>
